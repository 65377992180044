.container {
  width: 12rem;
  height: 6rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 1rem;

  // background: var(--token-card-bg-color);
}

.firstLine {
  width: 100%;
  justify-content: space-around;

  & > .name {
    font-weight: 600;
    color: #adb3bf;
  }
}

.priceUp {
  color: #06b470;
}

.priceDown {
  color: #f74e2c;
}

.priceTrendImg {
  width: 1rem;
  height: 1rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.priceUpImg {
  background: url('../../assets/image/price/up.png');
}

.priceDownImg {
  background: url('../../assets/image/price/down.png');
}

.price {
  // font-family: 'Source Sans 3';
  font-weight: 600;
}
