/* @src/components/Modal.module.css */

.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.centered {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 600px;
  height: auto;
  background: var(--modal-bg-color);
  color: var(--text-color);
  z-index: 2;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.closeBtnFlex {
  padding: 2rem 1rem;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 3rem;
}

.modalContent {
  padding: 10px;
  font-size: 14px;

  max-height: 70vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.modalContent::-webkit-scrollbar {
  display: none;
}

.modalContentWithoutCloseBtn {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 300px;
    height: auto;
  }

  .closeBtnFlex {
    padding: 1rem 0.8rem;
  }
}
