.title {
  font-family: Poppins;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.5rem;
  letter-spacing: -0.05rem;
}

.accountBalance {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.02rem;
  .balance {
    font-weight: 700;
    margin-left: 6px;
  }
}

.input {
  height: 4rem !important;
  background: #fff;
}

.max {
  color: var(--link-color);
  font-size: 1rem;
  height: 4rem !important;
  margin-right: 1.15rem;
  cursor: pointer;
}

.withdrawButton {
  background: var(--accent-color);
  font-size: 1rem;
}
