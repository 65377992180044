@use '../../variables' as var;

.container {
  align-items: start;
  flex-direction: column;
  row-gap: 2rem;
  padding-bottom: 3rem;
  font-weight: 500;

  padding-left: 3rem;
  padding-right: 3rem;
}
.mainTitle {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
}
.title {
  @include var.h2-text;
}

.detail {
  @include var.detail-text;
}

.tip {
  color: #f74e2c;
}

ul {
  li {
    margin-left: 2rem;
  }
}

.agreeButton {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .container {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .mainTitle {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
