@use '../../variables' as var;

.mainFlex {
  width: 600px;
  max-width: 100%;
  flex-direction: column;
  gap: 1rem;
}

.video {
  mix-blend-mode: var(--earlybird-video-blend-mode);
}

.title {
  @include var.h2-text;
}

.introFlex {
  padding: 2rem;
  width: 100%;
  flex-direction: column;
  border-radius: 1.5rem;
  gap: 1rem;

  img {
    width: 4rem;
  }
}
.intro1Colors {
  background-color: var(--earlybird-intro1-bg-color);

  .title {
    color: var(--earlybird-intro1-title-color);
  }
  .detail {
    color: var(--earlybird-intro1-text-color);
  }
  .listItem {
    font-weight: 700;
    color: #f7931a;
  }
}
.intro2Colors {
  background-color: var(--earlybird-intro2-bg-color);

  .title {
    color: var(--earlybird-intro2-title-color);
  }
  .detail {
    color: var(--earlybird-intro2-text-color);
  }
}
.intro3Colors {
  background-color: var(--earlybird-intro3-bg-color);

  .title {
    color: var(--earlybird-intro3-title-color);
  }
  .detail {
    color: var(--earlybird-intro3-text-color);
  }
}

.nodesCount {
  font-weight: 700;
  color: #f74e2c;
}
