@use '../../../variables'as var;

.container {
  background-color: var(--register-background-color);
  color: var(--text-color);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 0 7.5rem;
}

.back {
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.32px;
  margin-bottom: 20px;
}

.contentWrapper {
  flex-direction: column;
  width: 480px;
}

.contentCard {
  padding: 20px;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
}

.menuIcon {
  height: 38px;
  width: 38px;
  margin-left: 20px;
  cursor: pointer;
}

.ppeIcon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.usdtBalance {
  margin: 16px 0;
  color: #777E90;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.28px;
  align-self: flex-end;
}

.createBtn {
  background: #00AFA5;
  padding: 10px 0;
  height: auto;
}

.noticeCard {
  margin-top: 20px;
  padding: 20px;
  flex-direction: column;
  color: #F74E2C;
  gap: 10px;

  border-radius: 20px;
  background: rgba(247, 78, 44, 0.10);
}

.inputMode {
  user-select: none;
  cursor: pointer;
  color: #394DEE;
}


@media screen and (max-width: 768px) {
  .container {
    padding: 2rem;
  }

  .contentWrapper {
    width: 100%;
  }
}