.JoinButtonBox {
  display: inline-block;
  border-radius: 28px !important;
  width: auto;
  height: 2.5rem;
  border-radius: 7px;
  background: #394dee;
  user-select: none;
  text-align: center;
  padding: 0 0.7rem;
  color: white;

  line-height: 2.5rem !important;
  text-align: center;
  cursor: pointer;
}

.outlinedButton {
  background: transparent;
  border: 1px solid #394dee;
  color: #394dee;
}
.disabledButton {
  filter: grayscale(0.5);
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}
.thickButton {
  height: auto;
  font-weight: 700;
  padding: 0.625rem 0;
  border-radius: 3.75rem !important;
}
.thinButton {
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
}