.container {
  margin-top: -1px;
  background-color: var(--trend-bg-color);
  color: var(--text-color);
  gap: 1rem;
}

.contentContainer {
  height: 100%;
}

.mainFlex {
  flex-direction: row-reverse;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  padding: 3rem 0;
}

.titleContainer {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.2rem;
}
.divider {
  height: 2px;
  width: 100%;
  margin-bottom: 2rem;
  background: var(--divider-bg-color);
}
.tokenCardOuterContainer {
  overflow: hidden;
  height: 26rem;
}

.tokenCardContainer {
  gap: 2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 2rem;
  & > div {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 1.4rem;
  }
}
