@use '../../variables'as var;

.container {
  background-color: var(--register-background-color);
  color: var(--text-color);
  width: 100%;

  padding-bottom: 7.5rem;
  letter-spacing: -0.02rem;
}

.contentContainer {
  margin: auto;
  max-width: 71.25rem;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: center;
}

.leftContainer,
.rightContainer {
  width: 560px;
  flex-direction: column;
  gap: 1.25rem;
}

.title {
  @include var.h1-text;
  padding-top: 4.375rem;
  margin-bottom: 2.5rem;
  width: 100%;
}

.avatar {
  width: 7.5rem;
  height: 7.5rem;
  padding: 0.75rem;
  border-radius: 50%;
}

.badge {
  width: 7.5rem;
  height: 7.5rem;
  padding: 0.5rem;
  margin-left: auto;
}

.personalDetails {
  flex-direction: column;
  justify-content: center;
  gap: 0.625rem;

  .address {
    font-weight: 700;
    font-size: 1rem;
  }

  .logout {
    color: #f74e2c;
    font-size: 0.875rem;
    cursor: pointer;
  }
}

.copyIcon {
  cursor: pointer;
}

.nodeDetails {
  width: 100%;
  justify-content: space-between;
}

.tokenDetails {
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.historyTitle {
  justify-content: space-between;
}

.historySummary {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
  color: #777e90;
  text-align: right;
  line-height: 140%;
  letter-spacing: -0.02rem;
}

.historySummaryItem {
  align-items: center;
}

.historySummaryHighlight {
  color: #394dee;
}

.personalCard {
  display: flex;
  width: 100%;
  align-items: center;

  justify-content: space-between;
  gap: 2.5rem;
  border-radius: 3.75rem;
  background: var(--register-section-bg-color);
  margin-bottom: 1.25rem;
  padding-right: 3rem;

  .logoutBtn {
    border-radius: 60px;
    border: 1px solid #f74e2c;
    color: #f74e2c;
    padding: 0.625rem 1.875rem;
    height: 100%;
    font-size: 0.875rem;
    cursor: pointer;
  }

  .diamond {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
}

.vip {
  position: relative;
  background: linear-gradient(90deg, #ffd363 0%, white 100%);
  padding-right: 0;
}

.leftPersonalPart {
  gap: 2.5rem;
}

.card {
  display: flex;
  width: 100%;
  padding: 0.625rem;
  flex-direction: column;
  gap: 1.25rem;

  border-radius: 1.25rem;
  background: var(--register-section-bg-color);
}

.tokenCard {
  @extend .card;
  gap: 0;
}

.cardTitle {
  color: #777e90;
  font-size: 1.5rem;
  font-weight: 600;
  gap: 0.65rem;

  align-items: center;
  height: fit-content;
}

.cardItem {
  padding: 0.63rem 0.94rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .cardItemLabel {
    color: #777e90;
  }

  .cardItemContent {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.ppeAvailableCard,
.ppePendingCard {
  width: 100%;

  .cardItemContent {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
  }
}

.tile {
  display: flex;
  padding: 0.625rem;
  flex-direction: column;
  gap: 1.25rem;

  border-radius: 0.625rem;
  background: var(--profile-tile-bg-color);
}

.tileTitle {
  color: #777e90;
}

.strongText {
  @include var.h2-text;
}

.overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.invitationLink {
  border-radius: 1.25rem;
  padding: 0.2rem 0.5rem;
  background: var(--profile-ref-link-bg-color);

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.refFirstLine {
  flex-wrap: wrap;
  justify-content: space-between;
}

.invitationCodeInfo {
  width: 10rem;
}

.invitationLinkInfo {
  width: 23rem;
}

.refSecondLine {
  flex-wrap: wrap;
  justify-content: space-between;

  &>div {
    width: 49%;
  }
}

.nodeDetails {
  flex-wrap: wrap;
}

.thinButton {
  font-size: 1rem;
}

.walletBtns {
  margin-right: 1.25rem;
  gap: 0.625rem;
  align-items: center;
}

.addTip {
  color: #394dee;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  letter-spacing: -0.32px;
  padding: 5px 20px;
  cursor: pointer;
}

.loadMore {
  width: 10rem;
}

@media screen and (max-width: 768px) {
  .container {
    padding-bottom: 0;
  }

  .title {
    padding-top: 0.625rem;
    margin-bottom: 2.25rem;
  }

  .avatar {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
  }

  .mobileBadge {
    position: absolute;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 3rem;
    top: 2.3rem;
  }

  .personalCard {
    padding: 0;
    justify-content: flex-start;
  }

  .vip {
    background: #fff2c2;
  }

  .personalDetails {
    gap: 0.625rem;
  }

  .leftPersonalPart {
    gap: 1.25rem;
  }

  .mobileContainer {
    flex-direction: column;
    width: 100%;
  }

  .tabs {
    margin-top: 1rem;

    div[role=tabpanel] {
      padding: 0.8rem 0;
    }

    div[role=tablist] {
      justify-content: space-evenly;

      div[role='tabpanel'] {
        padding: 0.8rem 0;
      }

      div[role='tablist'] {
        button {
          font-size: 1rem;
          font-weight: 600;
        }

        overflow-x: scroll;
        overflow-y: hidden;

        padding-bottom: 10px;
        border: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .cardItem,
    .tile {
      width: 100%;
    }

    .refSecondLine {
      gap: 0.5rem;

      &>div {
        width: 100%;
      }
    }

    .refFirstLine {
      gap: 0.5rem;
    }

    .tokenDetails {
      flex-direction: column;
      row-gap: 0.625rem;
      margin-bottom: 0.625rem;
    }
  }
}