@use '../../variables' as var;

.container {
  background-color: var(--register-background-color);
  color: var(--text-color);
  width: 100%;

  min-height: calc(100vh - 287px);
}

.mainContainer {
  padding-bottom: calc(100vh - 950px);
}

.contentContainer {
  margin: auto;
  width: 90%;
}

.title {
  @include var.h1-text;
  padding-top: 2.5rem;
  margin-bottom: 2rem;
}
.goBack {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  cursor: pointer;
}

.card {
  display: flex;
  width: 100%;
  padding: 0.625rem;
  flex-direction: column;
  gap: 1.25rem;

  border-radius: 1.25rem;
  background: var(--register-section-bg-color);
}

.cardTitle {
  display: flex;
  gap: 0.5rem;
  color: #777e90;

  font-size: 24px;
  font-weight: 600;
}

.cardItem {
  width: 12rem;
  padding: 1rem;
}

.performanceItem {
  width: 17rem;
}

.cardItemTitle {
  color: #777e90;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cardItemValue {
  font-size: 1.4rem;
  font-weight: 600;
}

.accentValue {
  color: var(--link-color);
}
.referralTreeContainer {
  width: calc(100% - 500px);
}
.referrals {
  padding: 1rem;
  width: 100%;
  // margin-top: 18rem;
}
.searchContainer {
  width: 300px;
}
.referralTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.referralContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
  border-radius: 1.25rem;
  background: var(--register-section-bg-color);
}
.searchContainer {
  width: 100%;
}
.searchInput {
  display: flex;
  gap: 1rem;
  width: 700px;
}
.referralDetails {
  border-left: 1px solid gainsboro;
}

.highLighted {
  color: blue;
}

.referralAddress {
  background-color: var(--referral-bg-color) !important;
  display: flex;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: #f7f8fb;
  user-select: none;
}

.referralLabel {
  display: inline-flex;
  padding: 0.625rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.625rem;
  // border-radius: 0.625rem;
  // border: 1px solid rgba(0, 0, 0, 0.10);
}

.referralLabelName {
  color: var(--detail-text-color);
}

.referralLabelValue {
  color: var(--link-color);
}
.referralLabelContainer {
  margin-top: 0.4rem;
  margin-left: 2.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  min-width: 300px;
}

.sticky {
  position: fixed;
  top: 120px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .contentContainer {
    margin: auto;
    width: calc(100% - 1rem);
  }

  .inputWrapper {
    width: 100%;
  }
}
