@use "../../variables"as var;

.walletsPanel {
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.walletBtn {
    width: 100% !important;
}

.modal {
    padding: 8px 20px 20px 20px;
    width: 450px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;

}

.walletIcon {
    margin: 10px;
    cursor: pointer;

    img {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 768px) {
    .modal {
        width: 300px;
    }
    .walletIcon {
        margin: 20px 20px;
    }
}