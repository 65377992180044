@use '../../variables' as var;

.container {
  // width: calc(5% - 12.5rem - 40px);
  width: 50%;
  height: fit-content;

  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.costAmount {
  color: var(--link-color);
}

.video {
  mix-blend-mode: var(--earlybird-video-blend-mode);
  margin-right: 5.45rem;
}

.sale {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-grow: 2;
}

.inputGroup {
  height: 4rem;
  border-radius: 16px;
  border: 1px solid #394dee;

  .inputAddon {
    border-radius: 16px 0 0 16px;
    border: 1px solid #394dee;
  }

  div {
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    font-size: 2rem;
  }

  input {
    height: 4rem;
  }
}

.cost {
  justify-content: center;
}

.title {
  justify-content: space-between;
}

.amountWrapper {
  font-size: 0.875rem;
}

.amount {
  color: #f74e2c;
  font-weight: 700;
  padding-left: 0.3rem;
}

.priceWrapper {
  font-size: 0.875rem;
  text-wrap: nowrap;

  img {
    margin-left: 0.2rem;
  }
}

.price {
  color: #f7931a;
  font-size: 0.875rem;
  font-weight: 700;
  padding-left: 0.2rem;

  width: 120px;
}

.newTermsAndConditions {
  border-radius: 3.125rem;
  border: 1px solid #f74e2c;
  background: rgba(57, 77, 238, 0.1);
  font-size: 0.875rem;

  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span {
    color: var(--link-color);
    cursor: pointer;
  }
}

.agreed {
  font-size: 0.875rem;
  flex-direction: row;
  padding: 0.625rem 1.5rem;
  border-radius: 3.125rem;
  border: 1px solid #06b470;
  background: rgba(6, 180, 112, 0.1);
  & span {
    color: var(--link-color);
    cursor: pointer;
  }
}

.buyButton {
  background: #f7931a;
}

.qrCodeSection {
  padding: 1rem;
  border-radius: 1.25rem;
  border: 1px solid var(--link-color);
  justify-content: space-between;
  gap: 2rem;
}

.qrCodeInfo {
  flex-direction: column;
  gap: 1rem;
}
.copyShadowAddress {
  width: 100%;
}

.balance {
  padding-left: 0.625rem;
  font-size: 0.875rem;
}

.deposit {
  color: #394dee;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.28px;
  margin-left: 1.25rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .agreed {
    align-items: center;
    width: 100%;
  }
  .agreeText {
    flex-direction: column;
  }
  .container {
    width: 100%;
    // margin-bottom: 2rem;
    padding: 0;
  }
  .balance {
    justify-content: center;
  }
  .sale {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .video {
    display: none;
  }
}
