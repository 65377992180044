@use "../../variables"as var;

.buttonImage {
  width: 35px;
}
.menuList {
  width: fit-content !important;
  min-width: unset !important;
  color: var(--text-color) !important;
  background: var(--background-color) !important;
}
.menuItem {
  background: var(--background-color) !important;
}

@media (max-width: 768px) {
  .buttonImage {
    width: 30px;
  }
}
