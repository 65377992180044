@use '../../../variables'as var;

.container {
  background-color: var(--register-background-color);
  color: var(--text-color);
  width: 100%;

  padding: 0 7.5rem;
  min-height: 500px;
}


.header {
  width: 100%;
  align-items: center;
}

.tableContainer {
  flex-direction: column;
}

.historyIcon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.historyTitle {
  color: #777E90;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.48px;
}

.back {
  cursor: pointer;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.32px;
  margin-bottom: 20px;
}

.table {
  width: 100%;
  color: black;

  td {
    text-align: center;
  }
}

.usdtIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.status {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.28px;
}

.ongoing {
  color: #00AFA5;
}

.cancel {
  color: #FF1055;
  font-size: 14px;
  letter-spacing: -0.28px;
  user-select: none;
  cursor: pointer;
}

.tableContainer {
  margin-top: 20px;
  padding: 15px;
  flex-direction: column;
  gap: 20px;

  background-color: #FFF;
  border-radius: 20px;
  background: #FFF;
  color: black;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 2rem;
  }

  .mobileHistoryContainer {
    flex-direction: column;
    gap: 20px;
  }

  .mobilehHistoryItem {
    padding: 1rem;
    border-radius: 8px;
    background-color: rgb(213, 213, 213);
  }

  .row {
    justify-content: space-between;
  }

  .mobileRowCancel {
    color: #FF1055;
    padding: 1px 4px;
    border: 1px solid #FF1055;
    border-radius: 4px;
  }
}