.container {
  width: 15rem;
  height: 25rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 1rem;

  background: var(--token-card-bg-color);

  border-radius: 0.8rem;
  border: 0.15rem solid var(--token-card-border-color);
  user-select: none;
}

.img {
  width: 3rem;
  height: 3rem;
}

.priceUp {
  color: #06b470;
}

.priceDown {
  color: #f74e2c;
}

.priceTrendImg {
  width: 1rem;
  height: 1rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.priceUpImg {
  background: url('../../assets/image/price/up.png');
}

.priceDownImg {
  background: url('../../assets/image/price/down.png');
}

.price {
  font-family: 'Source Sans 3';
  font-weight: 600;
}

.chart {
  width: 15rem;
  height: 6rem;
  overflow: hidden;
  position: relative;
}

.innerChartContainer {
  position: absolute;
  top: -3rem;
  width: 15rem;
  height: 15rem;
}
