
.title {
  font-family: Poppins;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.5rem;
  letter-spacing: -0.05rem;
}

.balance {
  font-size: 1rem;
  & > span {
    font-weight: 700;
  }
}

.input {
  height: 4rem !important;
  background: #fff;
}

.depositButton {
  background: #06b470;
  font-size: 1rem;
}

.address {
  word-break: break-all;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.02rem;
}

.noticeFlex {
  padding: 1.25rem;
  flex-direction: column;
  background: var(--node-sale-notice-bg-color);
  border-radius: 1.5rem;
  color: #f74e2c;

  .noticeTitle {
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: -0.48px;
    line-height: 140%;

    & > img {
      width: 2rem;
    }
  }

  .noticeDetail {
    margin-top: 0.625rem;
  }
}
