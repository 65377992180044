.prosperExWrapper {
  background-color: var(--whyus-bg-color);
  color: var(--text-color);
  padding-bottom: 4rem;
  .prosperExMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    > h1 {
      // color: var(--neutrals-2, #23262f);
      text-align: center;

      /* P_Headline 2 */
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 64px; /* 133.333% */
      letter-spacing: -0.96px;
      margin-bottom: 1rem;
    }
    .exCardWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .prosperExWrapper {
    padding-bottom: 0;
  }
  .prosperExMainContainer {
    padding: 10px 0;
  }
}
