.container {
  background-color: var(--background-color);
  color: var(--text-color);

  background-image: var(--earlybird-bg-img);
  background-position-y: -10rem;
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: cover;
  background-attachment: fixed;
}

.contentContainer {
  height: 100%;
  padding-top: 0;
}

.mainFlex {
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  height: 100%;
  padding: 3rem 0;
}

.depositSkeleton {
  width: 600px;
  height: 600px;
}

@media screen and (max-width: 768px) {
  .mainFlex {
    padding-top: 0;
  }
  .depositSkeleton {
    display: none;
  }
}
