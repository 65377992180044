@use '../../variables' as var;

.nodeSaleWrapper {
  background: var(--node-sale-mini-node-sale-bg-color);
  color: var(--text-color);
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  gap: 8rem;
}
.container {
  width: 100%;
  position: relative;
  padding: 4rem;
}

.contentContainer {
  margin: auto;
  max-width: 71.25rem;
  column-gap: 20px;
  row-gap: 30px;
  justify-content: space-between;
  align-items: center;
  min-height: 500px;

  > img {
    width: 60%;
    padding-left: 15%;
    mix-blend-mode: hard-light;
  }
}

.mountainPlayer {
  position: absolute;
  width: 100%;
  top: 6rem;
  opacity: 0.2;
  mix-blend-mode: exclusion;
}
.leftPart {
  flex-direction: column;
}

.title {
  font-family: Poppins;
  margin: 0 auto;
  max-width: 71.25rem;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.06rem;
  // margin-bottom: 3.75rem;
  text-transform: uppercase;
  // margin-top: 3rem;
  > span {
    background: linear-gradient(180deg, #394dee 11.89%, #10b3f9 84.42%);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
  }
}

@media screen and (max-width: 768px) {
  .nodeSaleWrapper {
    gap: unset;
  }
  .container {
    padding: 4rem 2rem;
  }
  .contentWrapper {
    margin: 0;
    width: 100%;
    padding-top: 0;
  }
  .contentContainer {
    height: fit-content;
    min-height: auto;
  }
  .mainContainer {
    overflow-x: hidden;
    padding-top: 0;
  }
  .mobileContainer {
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin-top: 5rem;
    > img {
      margin-top: -20%;
      mix-blend-mode: hard-light;
      width: 100%;
    }
  }
  .title {
    text-align: center;
    font-size: 40px;
    margin-top: 0;
  }
}
