@use '../../variables' as var;

.applicationCardWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  gap: 2rem;
  padding: 10px;
}
.button {
  padding-left: 2rem;
  padding-right: 2rem;
}

.mediaWrapper {
  flex: 3;
}
.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  mix-blend-mode: var(--earlybird-video-blend-mode);
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 20%;
}

.contentWrapper {
  flex: 7;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 2rem;
  > h1 {
    color: var(--prosperex-h1-color);

    /* P_Headline 2 */
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 64px; /* 133.333% */
    letter-spacing: -0.96px;
  }
  > p {
    color: var(--text-color);

    /* Body_Dark */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
  }
}
@media screen and (max-width: 768px) {
  .applicationCardWrapper {
    margin-top: 1rem;
  }
  .mediaWrapper {
    display: none;
  }
}
