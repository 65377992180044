body {
  margin: 0;
  font-family: 'Poppins', 'DM Sans', 'Source Sans 3' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --background-color: white;
  --text-color: black;
  --accent-color: #f74e2c;

  --link-color: #394dee;

  --header-bg-color: white;
  --header-mobile-bg-color: #f7f8fb;
  --whyus-bg-color: white;
  --detail-text-color: #777e90;
  --trend-bg-color: #f7f8fb;
  --banner-bg-color: white;
  --welcome-bg-color: #627eea;

  --logo-img: url('assets/image/logo.png');

  --token-card-bg-color: white;
  --token-card-border-color: #e9eaee;

  --divider-bg-color: linear-gradient(90deg, rgba(193, 193, 193, 0.2) 0%, rgba(186, 184, 184, 0.04) 100%);
  --price-divider-bg-color: rgba(0, 0, 0, 0.2);

  /*components*/
  --modal-bg-color: #f3f4fa;

  /* earlybird */
  --earlybird-price-bg-color: #f3f4fa;
  --earlybird-notice-bg-color: rgba(247, 78, 44, 0.1);
  --earlybird-deposit-label-text-color: #000;
  --earlybird-bg-img: url('assets/image/earlybird/bg-light.png');

  --earlybird-intro1-bg-color: #f6f8ff;
  --earlybird-intro2-bg-color: #e0f6ee;
  --earlybird-intro3-bg-color: #e6d9fa;

  --earlybird-intro1-title-color: #394dee;
  --earlybird-intro1-text-color: #777e90;
  --earlybird-intro2-title-color: #00afa5;
  --earlybird-intro2-text-color: #00afa5;
  --earlybird-intro3-title-color: #7339ee;
  --earlybird-intro3-text-color: #3d4da0;

  --earlybird-video-blend-mode: difference;
  --earlybird-terms-bg-color: #f3f4fa;
  --earlybird-agreed-bg-color: rgba(6, 180, 112, 0.1);

  /* register */
  --register-background-color: #f7f8fb;
  --register-section-bg-color: #fff;

  /* node sale */
  --node-sale-countdown-bg-color: #f3f4fa;
  --node-sale-countdown-txt-color: #394dee;
  --node-sale-notice-bg-color: rgba(247, 78, 44, 0.1);

  --node-sale-section-bg-color: #fff;
  --node-sale-terms-aggreed-bg-color: rgba(6, 180, 112, 0.1);
  --node-sale-mini-node-sale-bg-color: linear-gradient(180deg, #cadef8 0%, #fff 100%);
  --node-sale-mini-node-sale-benefit-text-bg-color: #f8f8f8;

  /* profile */
  --profile-tile-bg-color: #f7f8fb;
  --profile-ref-link-bg-color: #dbdfec;

  /* new Design */
  --chaindata-data-color: #000;
  --prosperex-h1-color: var(--neutrals-2, #23262f);
  --sign-background-color: #313a5a;

  --referral-bg-color: #ecf0f9;
}

.theme-dark {
  --background-color: #000641;
  --text-color: white;

  --header-bg-color: #000531;
  --header-mobile-bg-color: #000836;
  --whyus-bg-color: #000531;
  --detail-text-color: #9eacf3;
  --trend-bg-color: #000639;
  --banner-bg-color: #000639;
  --welcome-bg-color: #2b3484;

  --logo-img: url('assets/image/logo-dark.png');

  --token-card-bg-color: transparent;
  --token-card-border-color: #142173;

  --divider-bg-color: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.04) 0%,
    rgba(255, 255, 255, 0.2) 48.42%,
    rgba(255, 255, 255, 0.04) 100%
  );
  --price-divider-bg-color: rgba(255, 255, 255, 0.2);

  /*components*/
  --modal-bg-color: #142173;

  /* earlybird */
  --earlybird-price-bg-color: #142173;
  --earlybird-notice-bg-color: #000641;
  --earlybird-deposit-label-text-color: #9eacf3;
  --earlybird-bg-img: url('assets/image/earlybird/bg-dark.png');

  --earlybird-intro1-bg-color: #142173;
  --earlybird-intro2-bg-color: #052d68;
  --earlybird-intro3-bg-color: #260261;

  --earlybird-intro1-title-color: white;
  --earlybird-intro1-text-color: #9eacf3;
  --earlybird-intro2-title-color: white;
  --earlybird-intro2-text-color: #9eacf3;
  --earlybird-intro3-title-color: white;
  --earlybird-intro3-text-color: #9eacf3;

  --earlybird-video-blend-mode: screen;
  --earlybird-terms-bg-color: #0e1663;
  --earlybird-agreed-bg-color: rgba(6, 180, 112, 0.1);

  /* register */
  --register-background-color: #000628;
  --register-section-bg-color: #000836;

  /* node sale */
  --node-sale-countdown-bg-color: #000641;
  --node-sale-countdown-txt-color: #fff;
  --node-sale-notice-bg-color: rgba(247, 78, 44, 0.1);

  --node-sale-section-bg-color: #000836;
  --node-sale-terms-aggreed-bg-color: rgba(6, 180, 112, 0.1);
  --node-sale-mini-node-sale-bg-color: linear-gradient(180deg, #010626 0%, #0a165f 100%);
  --node-sale-mini-node-sale-benefit-text-bg-color: #0e1c72;

  /* profile */
  --profile-tile-bg-color: #00174b;
  --profile-ref-link-bg-color: #000836;

  /* new Design */
  --chaindata-data-color: #fff;
  --prosperex-h1-color: #fff;
  --sign-background-color: #000641;

  --referral-bg-color: #00174b;
}
