@use "../../variables"as var;


.container {
  background-color: var(--register-background-color);
  color: var(--text-color);
  width: 100%;

}

.mainContainer {
  padding-bottom: calc(100vh - 950px);
}

.contentContainer {
  margin: auto;
  width: 375px;
}

.title {
  @include var.h1-text;
  padding-top: 100px;
  margin-bottom: 60px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 1.25rem;
  width: 23.4375rem;
  flex-shrink: 0;
  border-radius: 1rem;
  background-color: var(--register-section-bg-color);
}

.gotoRegister {
  text-align: center;
  margin-top: 2.5rem;
  padding-bottom: 9rem;
  &>a{
    color: blue;
  }
}

@media screen and (max-width: 768px) {
  .contentContainer {
    margin: auto;
    width: calc(100% - 1rem);
  }
  .inputWrapper {
    width: 100%;
  }
}