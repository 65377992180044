@use '../../variables' as var;

.container {
  color: var(--text-color);
  width: 100%;
}

.table {
  width: 100%;
  text-align: center;

  & > thead {
    color: #777e90;
    width: 100%;
    height: 50px;

    th {
      width: 30%;
    }
  }

  & > tbody {
    tr {
      height: 40px;
    }
  }
}

.mobileItemContainer {
  flex-direction: column;
  gap: 0.62rem;
  margin-top: 1rem;
}

.mobileItemFlex {
  display: flex;
  width: 100%;
  padding: 0.62rem 1.25rem;
  flex-direction: column;
  gap: 0.62rem;
  border-radius: 1.25rem;
  background: var(--register-section-bg-color);
}

.mobileItemAttr {
  justify-content: space-between;
}

.mobileItemAttrLabel {
  color: #777e90;
}

.mobileItemAttrValue {
  display: flex;
  font-weight: 500;
  gap: 10px;
}
