.container {
  background-color: var(--whyus-bg-color);
  color: var(--text-color);
  gap: 1rem;
}

.contentContainer {
  height: 100%;
}

.mainFlex {
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  padding: 3rem 0;

  > img {
    margin-top: 3rem;
  }
}

.title {
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  line-height: 4rem;
  padding-bottom: 2rem;
}

.description {
  width: 100%;
  text-align: center;
  color: var(--detail-text-color);
  margin-bottom: 2rem;
}

.whyUsIntroWrapper {
  width: 100%;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .PhoneFrame {
    min-width: 25%;
    position: relative;
  }
  .triangle {
    width: 100%;
    height: 0;
    padding-top: 211%;
    position: relative;
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2.5rem;
    outline: 5px solid #3a4245;
    border: 10px solid black;
    overflow: hidden;
  }
  .actuallyFrame {
    width: 100%;
    height: 100%;
  }
}

.dexDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  .introCardImgWrapper {
    width: 100%;
    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  > h1 {
    color: var(--text-color);
    text-align: center;

    margin-top: 1rem;
    /* Headline_3 */
    font-family: Poppins;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 56px */
    letter-spacing: -0.8px;
  }

  > h6 {
    color: var(--text-color);
    text-align: center;

    /* Content_1 */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
  }

  > p {
    color: #777e90;
    text-align: justify;

    /* Body_Dark */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
  }
}

@media screen and (max-width: 768px) {
  .contentContainer {
    padding: 10px 0;
  }
  .mainFlex {
    padding-bottom: 0;
  }
  .title {
    font-size: 2rem;
    line-height: 2.2rem;
  }
  .description {
    font-size: 0.8rem;
  }
  video {
    width: 100%;
  }
  .PhoneFrame {
    min-width: 55% !important;
  }
  .content {
    border-radius: 15px !important;
  }
  .triangle {
    padding-top: 210% !important;
  }
}
