.container {
  background-color: var(--header-bg-color);
  color: var(--text-color);
  gap: 1rem;
}

.contentContainer {
  height: 100%;
}

.mainFlex {
  flex-direction: row-reverse;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;
  padding: 3rem 0;
  width: 100%;

  background: url('../../assets/image/banner/hexagon.png');
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: right;
}

.textContainer {
  width: 40%;
  min-width: 25rem;
  flex-wrap: wrap;
  gap: 2rem;

  padding-bottom: 10rem;
}
.appWrapper {
  gap: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    cursor: pointer;
    display: block;
    // flex: 1;
    height: 100%;
    > svg {
      width: 100%;
      height: 100%;
    }
  }
}
.chainDataWrapper {
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.worldImg {
  width: 35rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
}

.worldImg img {
  width: 100%;
}

.joinBtn {
  color: white;
  background-color: #394dee;
}

.description {
  color: var(--detail-text-color);
}

.title {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 3.8rem;
}

.priceCardOuterContainer {
  margin-top: 6rem;

  // span all window width
  // overflow: hidden;
  width: 120vw;
  height: 6rem;
}

.priceCardContainer {
  gap: 2rem;
  overflow: hidden;
}

.divider {
  height: 3rem;
  width: 2px;
  background-color: var(--price-divider-bg-color);
}

@media screen and (max-width: 768px) {
  .mainFlex {
    background-position: top;
    background-size: 100%;
  }
  .worldImg {
    height: 22rem;
  }
  .worldImg img {
    width: 20rem;
  }
  .mainFlex {
    padding-top: 0;
    padding-bottom: 0;
  }
  .contentContainer {
    padding-top: 0;
    padding-bottom: 0;
  }
  .title {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .description {
    font-size: 0.8rem;
  }
  .textContainer {
    padding: 0 2rem;
  }
  .chainDataWrapper {
    flex-direction: column;
    margin-top: 1rem;
  }
  .appWrapper {
    flex-wrap: wrap;
    height: 100%;
  }
}
