.container {
  background-color: #000641;
  color: var(--text-color);
  gap: 1rem;
}

.contentContainer {
  height: 100%;
}

.mainFlex {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;
  height: 100%;
  padding: 3rem 0;
}

.logo {
  background: var(--logo-img);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 10rem;
  height: 2rem;
  cursor: pointer;
}

.menuContainer {
  gap: 1rem;
}

.copyrightFlex {
  justify-content: space-between;
  row-gap: 1rem;
}

.menuItem {
  user-select: none;
  cursor: pointer;
}

.socialContainer {
  & > img {
    user-select: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .mainFlex {
    justify-content: space-around;
  }

  .copyrightFlex {
    flex-direction: column-reverse;
    justify-content: space-around;
    font-size: 0.8rem;
  }

  .contentContainer {
    padding: 0;
  }
  .menuContainer {
    // flex-direction: column;
    gap: 1rem;
  }
}
