@use '../../variables' as var;

.container {
  display: flex;
  padding: 3rem 0;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  border-radius: 1.875rem;
  border: 1px solid #e1e1e1;

  img {
    width: 13rem;
    height: 13rem;
  }
}

.detailWrapper {
  display: flex;
  padding: 1.875rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;

  .miniNodeBenefits {
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    .miniNodeBenefitsDetail {
      padding: 1.2rem;
      border-radius: 10px;
      background: var(--node-sale-mini-node-sale-benefit-text-bg-color);
      > span {
        background: linear-gradient(180deg, #394dee 11.89%, #10b3f9 84.42%);
        -webkit-background-clip: text;
        color: transparent;
        background-clip: text;
      }
    }
  }
}

.detail {
  color: var(--detail-text-color);
}

.title {
  @include var.h2-text;
}
