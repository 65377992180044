.signUpWrapper {
  width: 100%;
  background: var(--sign-background-color);
  font-family: DM Sans;
  .mainContainer {
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    .contentWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
      > img {
        flex: 1 1 40px;
      }
      > h2 {
        color: var(--white, #fff);
        font-size: 24px;
        font-style: normal;
        line-height: 55.02px; /* 229.25% */

        > span {
          color: var(--white, #fff);
          font-size: 42px;
          line-height: 100%;
          text-transform: uppercase;
        }
      }
    }
    .inputWrapper {
      display: flex;
      align-items: center;
      border-radius: 100px;
      border: 2px solid var(--white, #fff);
      background: var(--white, #fff);
      // width: 100%;
      padding: 10px;
      .input {
        border-radius: 100px;
        flex-grow: 2;
        border: none;
      }
      .input:focus {
        outline: none;
        border: none !important;
      }
      .subscribe {
        border-radius: 100px;
        min-width: 100px;
        cursor: pointer;
      }
    }
    .inputWrapper:focus-within {
      outline: 1px solid blue;
    }
  }
}
@media screen and (max-width: 768px) {
  .mainContainer {
    flex-direction: column;
    align-items: center;
    .contentWrapper {
      > img {
        display: none;
      }
    }
  }
}
