@use '../../variables'as var;

.container {
  background-color: var(--register-background-color);
  color: var(--text-color);
  width: 100%;

  padding: 7.5rem;
}


.header {
  width: 100%;
  justify-content: space-between;
}

.tableContainer {
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 2rem 0;
  }
}