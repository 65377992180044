@use "../../variables"as var;


.container {
  background-color: var(--register-background-color);
  color: var(--text-color);
  width: 100%;
}

.mainContainer {
  padding-bottom: calc(100vh - 1020px);
}

.contentContainer {
  margin: auto;
  width: 375px;
}

.title {
  @include var.h1-text;
  padding-top: 100px;
  margin-bottom: 60px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 1.25rem;
  width: 23.4375rem;
  flex-shrink: 0;
  border-radius: 1rem;
  background-color: var(--register-section-bg-color);
}

.invitationCodeInput {
  height: 4rem;
  border-radius: 1rem;
  border: 1px solid #B8B8B8;
  background: var(--background-color);

  &::placeholder {
    color: var(--text-color);;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 700;
    line-height: 140%;
    /* 1.4rem */
    letter-spacing: -0.02rem;
    opacity: 0.2;
  }
}

.gotoLogin {
  text-align: center;
  margin-top: 2.5rem;
  padding-bottom: 9rem;
  &>a{
    color: blue;
  }
}

@media screen and (max-width: 768px) {
  .contentContainer {
    margin: auto;
    width: calc(100% - 2rem);
  }
  .inputWrapper {
    width: 100%;
  }
}