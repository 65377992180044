@use '../variables' as var;

.container {
  background-color: var(--background-color);
  color: var(--text-color);

  li {
    margin-left: 20px;
  }
}

.contentContainer {
  padding-top: 60px;
  max-width: 900px;
}

.Page-title-section {
  text-align: center;
}

.section {
  padding-bottom: 4.65rem;
}

.Page-title {
  @include var.h1-text;
}

.Page-subtitle {
  @include var.h2-text;
  margin: 1.5rem 0;
}

.content {
  margin-top: 4.65rem;
}

.body-text {
  margin: 0px;
  font-weight: 400;
  font-size: var(--font-base);
  line-height: 2.4rem;
  margin-bottom: 1.5rem;
  opacity: 0.7;
}

.body-title {
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: var(--font-lg);
}

.list-style-none {
  list-style: none;
}

.list-style-none li {
  padding-bottom: 0;
}
