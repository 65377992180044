@use "../../variables"as var;

.container {
  background-color: transparent;
  color: var(--text-color);
  width: 100%;

  position: relative;
  top: -1px;
}

.contentWrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;

  margin-bottom: 10vh;
}

.banner {
  flex-direction: column;
  align-items: center;
}

.mountainPlayer {
  height: calc(100vh - 80px);
  overflow: hidden;
  background-image: url('../../assets/image/treasury/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 64px;
  font-weight: 700;
}

.comingSoon {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 80px;
  font-weight: 200;
  letter-spacing: 40px;
  text-transform: uppercase;

  justify-content: space-between;
  gap: 60px;
  flex-wrap: wrap;
}

.content {
  justify-content: space-between;
  flex-direction: column;
  gap: 50px;
}

.details {
  width: 480px;
  font-family: Inter;
  font-size: 2rem;
  justify-content: space-between;
  color: white;
}

.divider {
  width: 2px;
  height: 100%;
  background-color: white;
  opacity: 0.2;
}

.subscribe {
  flex-direction: column;
  height: 64px;
  gap: 50px;
  align-items: center;
}

.subscribeText {
  height: 64px;
  line-height: 64px;
  align-self: center;
}

.subscribeText1 {
  color: #394DEE;
  margin-right: 10px;
}

.subscribeText {
  height: 64px;
  margin-right: 40px;

  &:hover {
    .subscribeTextWrapper {
      display: none;
    }

    .inputWrapper {
      display: block;
    }
  }
}


.inputWrapper {
  display: none;
  align-items: center;
  width: 460px;
  border-radius: 100px;
  border: 2px solid black;
  background: black;
  padding: 10px;

  .input {
    border-radius: 100px;
    flex-grow: 2;
    border: none;
    color: white;
  }

  .input:focus {
    outline: none;
    border: none !important;
  }

  .subscribe {
    border-radius: 100px;
    min-width: 100px;
    cursor: pointer;
  }
}

.enterIcon {
  cursor: pointer;
}

.socialWrapper {
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 10px;
}

.social {
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 768px) {
  .container {
    max-height: 100%;
    // background-color: #000641;
    // background-color: var(--background-color);
    background-color: transparent;
  }

  .contentWrapper {
    height: 90%;
    margin: 0;
    justify-content: space-between;
    // overflow: hidden;
  }

  .inputWrapper {
    height: 44px;
    padding: 0;

    input {
      width: 70%;
      line-height: 44px;
    }
  }

  .subscribeTextWrapper{
    flex-direction: column;
    align-items: center;
    gap: 0px;

    .subscribeText1, subscribeText2 {
      line-height: 16px;
    }
  }

  .mountainPlayer {
    position: absolute;
    width: 100%;
  }

  .subscribeText {
    margin: 0;
  }


  .divider {
    width: 2px;
    background-color: white;
    height: 26px;
  }

  .title {
    font-size: 40px;
    text-align: center;
    margin: 60px 0 15px 0;
  }

  .comingSoon {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 40px;
    font-weight: 100;
    letter-spacing: 10.8px;
    text-transform: uppercase;
    justify-content: center;
    gap: 0px;
  }

  .details {
    font-size: 16px;
    width: 60%;
  }

  .content {
    width: 100%;
    height: 30%;
    align-items: center;
    justify-content: center;
    margin-top: 20%;
  }

  .inputWrapper {
    width: 100%;
  }

  .contentWrapper {
    position: relative;
    padding: 0 10px 10px;
  }

  .subscribe {
    flex-direction: column;
  }

  .inputEmailSection {
    font-size: 12px;
    margin: 10px 0 10px 0;
  }
}