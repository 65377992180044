.container {
  position: sticky;
  top: 0;
  background-color: var(--header-bg-color);
  color: var(--text-color);
  height: 5rem;
  gap: 1rem;
  z-index: 9;
}

.contentContainer {
  height: 100%;
}

.mainFlex {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 2rem;
}

.logo {
  background: var(--logo-img);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 10rem;
  height: 2rem;
  cursor: pointer;
}

.menuContainer {
  gap: 1rem;
  flex-grow: 1;
}

.menuItem {
  user-select: none;
  cursor: pointer;
  width: max-content;
}

.download {
  background-color: var(--header-bg-color);
  color: var(--text-color);
  cursor: pointer;
}
.PopoverContent {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.logoAndBurgerWrapper {
  width: 100%;
  display: flex;
  justify-content: space-start;
  align-items: center;
}
.hamburger {
  width: 25px;
  display: none !important;
}

@media screen and (max-width: 768px) {
  .menuFlex {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    top: 80px;
    right: 0px;
    z-index: 10;
    width: 21rem;
    height: calc(100% - 80px);
    background-color: var(--background-color);
  }
  .mobileMenuContainer {
    flex-direction: column;
    justify-content: flex-start;
    // align-items: center;

    // opacity: 0.5;
    // border-radius: 0 0 5px 5px;
    flex-grow: 0;
    row-gap: 10px;
    padding: 40px 0;

    font-size: 1rem;
    font-weight: 600;
  }
  // .themeSwitch {
  //     display: none !important;
  // }
  .hamburger {
    display: unset !important;
  }

  .mobileMenuFooterWrapper {
    width: 16rem;
    flex-direction: column;
    gap: 1rem;
  }
  .hamburgerMenuFlex{
    flex-direction: column;
    justify-content: space-between;
  }

  .logoAndBurgerWrapper {
    justify-content: space-between;
    padding-right: 1rem;
  }
  .socialContainer {
    justify-content: space-between;
    width: 100%;
    padding-bottom: 2rem;
  }
  .personal {
    width: 100%;
    align-items: center;
    flex-direction: column;
    img{
      width: 5rem;
      height: 5rem;;
    }
    .address {
      font-weight: 700;
    }
    .logout {
      color: var(--accent-color);
    }
  }
}
