@use '../../variables' as var;

.mainFlex {
  position: fixed;
  top: 160px;
  height: 100vh;
  width: 600px;
  row-gap: 2rem;

  flex-direction: column;
}

.title {
  @include var.h1-text;
}

.priceFlex {
  background-color: var(--earlybird-price-bg-color);
  width: 100%;
  height: 5rem;
  border-radius: 1.5rem;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.priceLabel {
  @include var.detail-text;
  font-weight: 700;
  gap: 1rem;
  align-items: center;

  & > img {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.priceItem {
  @include var.detail-text;

  & > span {
    font-weight: 700;
    color: #f5990d;
  }
}

.priceItemsFlex {
  flex-direction: column;
  height: 5rem;
  justify-content: space-evenly;
}

.depositDescFlex {
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
  @include var.detail-text;

  & > .depositDesc {
    color: var(--earlybird-deposit-label-text-color);
  }

  & > .depositTip {
    color: #f74e2c;
  }

  & > .depositAddress {
    justify-content: space-between;
    border-radius: 1.25rem;
    border: 2px solid #394dee;
    width: 100%;
    padding: 1.2rem 1.5rem;

    .copyIcon {
      color: #394dee;
    }
  }
}

.termsAndConditions {
  background-color: var(--earlybird-terms-bg-color);
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  & > span {
    color: #394dee;
    cursor: pointer;
  }
}

.agreed {
  border: 1px solid #06b470;
  background-color: var(--earlybird-agreed-bg-color);
}
.infoFlex {
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1rem;
}

.qrCodeFlex {
  padding: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  border: 1px solid #394dee;
  line-height: 1.2rem;
  gap: 1rem;

  width: 40%;
}

.noticeFlex {
  padding: 1rem;
  width: 55%;
  flex-direction: column;
  background: var(--earlybird-notice-bg-color);
  border-radius: 1.5rem;
  color: #f74e2c;

  .noticeTitle {
    align-items: center;
    gap: 1rem;
    @include var.h2-text;

    & > img {
      width: 2rem;
      height: 2rem;
    }
  }

  .noticeDetail {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .mainFlex {
    position: relative;
    height: 100%;
    height: 900px;
    top: 80px;
  }

  .termsAndConditions {
    font-size: 0.6rem;
  }

  .qrCodeFlex,
  .noticeFlex {
    width: 100%;
  }

  .qrCodeDesc {
    width: 50%;
  }

  .priceLabelText {
    display: none;
  }

  .depositAddressText {
    font-size: 0.65rem;
  }
}
