@use '../../../variables'as var;

.container {
  background-color: var(--register-background-color);
  color: var(--text-color);
  width: 100%;

  padding: 0 7.5rem;
  min-height: 500px;
}


.header {
  width: 100%;
  justify-content: space-between;
  align-items: center;

}

.title {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.96px;
}

.historyIcon {
  height: 45px;
  width: 45px;
  margin-left: 20px;
  cursor: pointer;
}

.tableContainer {
  padding: 15px;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  background-color: #FFF;
  border-radius: 20px;
  background: #FFF;
  color: black;
}

.tableHeader {
  justify-content: space-between;
}

.bookIcon,
.ppeIcon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.price {
  color: #00AF70;
  font-weight: 700;
  letter-spacing: -0.32px;
  margin-left: 10px;
}

.tableTitle {
  color: #777E90;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.48px;
}

.table {
  width: 100%;
  color: black;

  td {
    text-align: center;
  }
}

.usdtIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.sell {
  color: #394DEE;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.28px;
  user-select: none;
  cursor: pointer;
}

.modal {
  padding: 20px;
  font-size: 16px;
}

.modalTitle {
  color: #000;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.48px;
  margin-bottom: 20px;
}

.modalOrderAmount {
  height: 56px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #E8ECFD;
  color: #394DEE;
  font-size: 24px;
  letter-spacing: -0.48px;
  margin: 18px 0;
}

.modalConfirmBtn {
  width: 100%;
  padding: 10px 0;
  height: auto;
}

.createOrderBtn{
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 2rem;
  }

  .title {
    font-size: 36px;
  }
  .tableTitle {
    font-size: 16px;
  }
}