.sponsorWrapper {
  background-color: var(--background-color);

  position: relative;
  .sponsorAndBackContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 1rem;
    > h2 {
      color: var(--text-color);
      /* Headline_3_dark */
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 33.6px */
      letter-spacing: -0.48px;
      margin: 4rem auto;
    }
    .investorsIconWrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
    }
    .iconWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4rem;
      flex-wrap: wrap;
    }
  }
}
@media screen and (max-width: 768px) {
  .sponsorWrapper {
    .sponsorAndBackContainer {
      padding: 0;
      > h2 {
        margin-bottom: 1rem;
      }
      .iconWrapper,
      .investorsIconWrapper {
        width: 100%;
        padding: 1rem 0;
        gap: 2rem;
        > img {
          display: block;
          width: 45%;
        }
      }
    }
  }
}
