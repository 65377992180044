.applicationWrapper {
  width: 100%;
  background: var(--header-bg-color);
}

@media screen and (max-width: 768px) {
  .applicationContainer {
    padding: 10px 0;
  }
}
